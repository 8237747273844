import Seo from 'src/components/seo/Seo'

import FitnessCardio from '../../components/Campanha/FitnessCardio'

function ColecaoFitness2022() {
  return (
    <>
      <Seo title="Campanha | Decathlon" />
      <FitnessCardio />
    </>
  )
}

export default ColecaoFitness2022
